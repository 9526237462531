import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Alert from '../components/Alert'

const Novidades = () => {
  return (
    <Layout>
      <SEO description='Nosso objetivo é trazer a você conforto e satisfação.' />
      <h1 className='invisible'>Novidades</h1>
      <div className='w-11/12 mx-auto mt-2 md:w-4/5'>
        <p className='flex robika w-full sm:w-4/5 lg:w-2/3 uppercase underlin text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl md:mt-10 mb-2 font-bold'>Novidades</p>
        <Alert title='Aguarde' >Em breve novidades.</Alert>
      </div>
    </Layout>
  )
}

export default Novidades
